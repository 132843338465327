<template>
  <b-card no-body>
    <p class="text-center">
      Réponses :
    </p>
    <b-row>
      <b-col
        v-for="answer in question.answers"
        :key="answer.uuid"
      >
        <div class="text-center mb-1">
          <b-badge
            v-show="(question.good_answer?question.good_answer.uuid : null) === answer.uuid"
            variant="light-success"
          >
            bonne réponse
            <feather-icon icon="CheckIcon" />
          </b-badge>
        </div>
        <div>
          <b-img
            center
            height="auto"
            width="50"
            :src="answer.image"
          />
        </div>
        <div class="text-center mt-1">
          <small>{{ answer.title['fr'] }}</small>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-form @submit.prevent="quickEdit">
          <b-row
            cols="5"
            class="justify-content-md-center"
          >
            <b-col>
              <b-form-group
                label="Visibilité"
              >
                <b-form-checkbox
                  v-model="question.is_visible"
                  :value="true"
                  :unchecked-value="false"
                >
                  <span class="vs-label">{{ question.is_visible ? 'Visible' : 'Invisible' }}</span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Status"
              >
                <v-select
                  v-model="question.status"
                  :dir="'ltr'"
                  :options="statusOptions"
                  class="w-100"
                  :clearable="false"
                  :reduce="val => val.value"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Bonne réponse"
              >
                <v-select
                  v-model="good_answer_id"
                  :get-option-label="option => option.title.fr"
                  :dir="'ltr'"
                  :options="question.answers"
                  :reduce="val => val.uuid"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="text-center">
            <b-button
              variant="primary"
              type="submit"
              :disabled="formLoading || !isQuestionModified"
            >
              <b-spinner
                v-show="formLoading"
                small
              />
              Enregistrer
            </b-button>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol, BForm, BFormCheckbox, BFormGroup, BImg,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'CompetitionsViewQuestionListQuickView',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BForm,
    BImg,
    BFormCheckbox,
    BFormGroup,
    vSelect,
    BBadge,
  },
  props: {
    question: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      statusOptions: [
        { label: 'À venir', value: 'PENDING' },
        { label: 'En cours', value: 'RUNNING' },
        { label: 'Terminé', value: 'FINISHED' },
      ],
      formLoading: false,
      questionReference: null,
      good_answer_id: this.question.good_answer ? this.question.good_answer.uuid : null,
    }
  },
  computed: {
    isQuestionModified() {
      return JSON.stringify(this.question) !== this.questionReference || this.good_answer_id !== (this.question.good_answer ? this.question.good_answer.uuid : null)
    },
  },
  mounted() {
    this.questionReference = JSON.stringify(this.question)
  },
  methods: {
    async quickEdit() {
      try {
        this.formLoading = true
        const data = {
          title_fr: this.question.title.fr,
          title_en: this.question.title.en,
          title_de: this.question.title.de,
          title_es: this.question.title.es,
          title_it: this.question.title.it,
          status: this.question.status,
          date_time: this.question.date_time,
          points_to_win: this.question.points_to_win,
          is_visible: this.question.is_visible,
          is_mail_reminder_sent: this.question.is_mail_reminder_sent,
          good_answer_id: this.good_answer_id,
        }
        const question = await axiosIns.post(`questions/${this.question.uuid}`, data)
        this.question._showDetails = false
        this.question.status = question.data.data.status
        this.question.is_visible = question.data.data.is_visible
        this.question.good_answer = question.data.data.good_answer
        this.good_answer_id = question.data.data.good_answer ? this.question.good_answer.uuid : null
        this.formLoading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La question à bien été mise a jour',
            },
          },
          { timeout: 5000 },
        )
      } catch (err) {
        this.formLoading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
