<template>
  <b-card no-body>
    <!-- filter -->
    <div>
      <b-card-header class="pb-50">
        <h5>
          Filtres
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              v-model="statusFilter"
              :dir="'ltr'"
              :options="statusOptions"
              class="w-100"
              :reduce="val => val.value"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Visibilité</label>
            <v-select
              v-model="visibilityFilter"
              :dir="'ltr'"
              :options="visibilityOptions"
              class="w-100"
              :reduce="val => val.value"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </div>
    <div class="m-2">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Afficher</label>
          <v-select
            v-model="perPage"
            :dir="'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
            @input="fetchQuestions(true)"
          />
          <label>entrées</label>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form @submit.prevent="fetchQuestions(true)">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                type="submit"
                variant="primary"
              >
                <span class="text-nowrap">Chercher</span>
              </b-button>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refUserListTable"
      class="position-relative"
      :items="questions"
      responsive
      :fields="tableColumns"
      primary-key="uuid"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Aucun résultat trouvé"
      :sort-desc.sync="isSortDirDesc"
      :no-local-sorting="true"
      :busy="loading"
      @sort-changed="sortQuestions($event)"
    >
      <!-- busy -->
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner label="Loading..." />
        </div>
      </template>

      <template #cell(show_details)="row">
        <b-button
          size="sm"
          variant="outline-primary"
          class="btn-icon"
          @click="row.toggleDetails"
        >
          <feather-icon
            v-show="!row.detailsShowing"
            icon="ChevronDownIcon"
          />
          <feather-icon
            v-show="row.detailsShowing"
            icon="ChevronUpIcon"
          />
        </b-button>
      </template>

      <template #row-details="row">
        <CompetitionsViewQuestionListQuickView :question="row.item" />
      </template>

      <!-- Column: titre -->
      <template #cell(title)="data">
        <span>{{ data.item.title['fr'] }}</span>
      </template>

      <!-- Column: point -->
      <template #cell(point)="data">
        <span>
          {{ data.item.points_to_win }}
        </span>
      </template>

      <!-- Column: Status -->
      <template #cell(status)="data">
        <b-badge :variant="resolveStatusVariant(data.item.status)">
          {{ data.item.status }}
        </b-badge>
      </template>

      <!-- Column: visibility -->
      <template #cell(visibility)="data">
        <feather-icon
          :icon="data.item.is_visible ? 'EyeIcon' : 'EyeOffIcon'"
          size="18"
          class="mr-50"
        />
      </template>

      <!-- Column: date_time -->
      <template #cell(date_time)="data">
        <span>{{ moment(data.value).format("LLL") }}</span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">

          <feather-icon
            :id="`competition-row-${data.item.uuid}-edit-icon`"
            icon="EditIcon"
            class="cursor-pointer mx-1"
            size="16"
            @click="$router.push({ name: 'questions-view', params: { idCompetition: competition.uuid, id: data.item.uuid }})"
          />
          <b-tooltip
            title="Éditer"
            class="cursor-pointer"
            :target="`competition-row-${data.item.uuid}-edit-icon`"
          />

          <feather-icon
            :id="`question-row-${data.item.uuid}-delete-icon`"
            v-b-modal="`modal-delete-question-${data.item.uuid}`"
            icon="TrashIcon"
            class="cursor-pointer"
            size="16"
          />
          <b-tooltip
            title="Supprimer"
            class="cursor-pointer"
            :target="`question-row-${data.item.uuid}-delete-icon`"
          />
          <b-modal
            :id="`modal-delete-question-${data.item.uuid}`"
            :ref="`modal-delete-question-${data.item.uuid}`"
            ok-variant="danger"
            ok-title="Supprimer"
            modal-class="modal-danger"
            centered
            title="Suppression de la question"
          >
            <b-card-text>
              Souhaitez-vous vraiment supprimer cette question ? <br>
              Ces éléments seront supprimés : <br>
              -Pronostiques fait sur cette réponse
              (Le classement sera recalculé)
            </b-card-text>
            <template #modal-footer>
              <b-button
                variant="secondary"
                @click="hideModal(data.item.uuid)"
              >
                annuler
              </b-button>
              <b-button
                variant="danger"
                :disabled="deleteQuestionLoading"
                @click="deleteQuestion(data.item.uuid)"
              >
                <b-spinner
                  v-show="deleteQuestionLoading"
                  small
                />
                supprimer
              </b-button>
            </template>
          </b-modal>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
            {{ dataMeta.total }} entrées</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination v-if="!loading"
            v-model="currentPage"
            :total-rows="totalQuestion"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="changePage($event)"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CompetitionsViewQuestionListQuickView from '@/views/competitions/competitions-view/CompetitionsViewQuestionListQuickView'
import {
  BBadge,
  BButton,
  BCard, BCardBody, BCardHeader, BCardText,
  BCol, BForm, BFormInput,
  BPagination,
  BRow,
  BSpinner,
  BTable, BTooltip, VBModal,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'

export default {
  name: 'CompetitionViewQuestionsList',
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BSpinner,
    BBadge,
    BTable,
    BForm,
    BFormInput,
    BPagination,
    BCardHeader,
    BCardBody,
    CompetitionsViewQuestionListQuickView,
    vSelect,
    BCardText,
    BTooltip,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const resolveStatusVariant = status => {
      if (status === 'PENDING') return 'light-info'
      if (status === 'RUNNING') return 'light-success'
      if (status === 'FINISHED') return 'light-warning'
      return 'light-success'
    }
    const resolveBooleanVariant = bool => (bool ? 'light-success' : 'light-danger')
    return {
      avatarText,
      resolveStatusVariant,
      resolveBooleanVariant,
    }
  },
  data() {
    return {
      loading: true,
      questions: [],
      tableColumns: [
        {
          key: 'show_details',
          label: 'Détail',
        },
        {
          key: 'title',
          label: 'Titre',
        },
        {
          key: 'point',
          label: 'Point',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'visibility',
          label: 'Visibilité',
        },
        {
          key: 'date_time',
          label: 'Date',
        },
        { key: 'actions' },
      ],
      sortBy: 'date_time',
      statusOptions: [
        { label: 'À venir', value: 'PENDING' },
        { label: 'En cours', value: 'RUNNING' },
        { label: 'Terminé', value: 'FINISHED' },
      ],
      visibilityOptions: [
        { label: 'Visible', value: true },
        { label: 'Invisible', value: false },
      ],
      statusFilter: null,
      visibilityFilter: null,
      isSortDirDesc: true,
      searchQuery: '',
      perPageOptions: [5, 25, 50, 100],
      perPage: 25,
      currentPage: 1,
      totalQuestion: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
        total: 0,
      },
      deleteQuestionLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      competition: 'competitions/competition',
    }),
  },
  watch: {
    statusFilter: {
      handler() {
        this.fetchQuestions(true)
      },
    },
    visibilityFilter: {
      handler() {
        this.fetchQuestions(true)
      },
    },
  },
  beforeMount() {
    const query = this.$route.query
    this.currentPage = Number(query.page) || 1
    this.perPage = Number(query.perPage) || 25
  },
  mounted() {
    this.fetchQuestions()
  },
  methods: {
    async fetchQuestions() {
      try {
        this.loading = true
        const params = {
          status: this.statusFilter,
          visibility: this.visibilityFilter,
          term: this.searchQuery,
          page: this.currentPage,
          perPage: this.perPage,
          sortBy: this.sortBy,
          sortDirection: this.isSortDirDesc ? 'desc' : 'asc',
        }
        this.$router.replace({ query: params })
        const { data } = await axiosIns(`/competitions/${this.$route.params.id}/questions`, { params })
        this.questions = data.data
        this.dataMeta.from = data.meta.from
        this.dataMeta.to = data.meta.to
        this.dataMeta.total = data.meta.total
        this.totalQuestion = data.meta.total
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async deleteQuestion(questionUuid) {
      try {
        this.deleteQuestionLoading = true
        await axiosIns.delete(`questions/${questionUuid}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La question a été supprimée',
            },
          },
          { timeout: 5000 },
        )
        this.deleteQuestionLoading = false
        this.hideModal(questionUuid)
        await this.fetchQuestions()
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.deleteQuestionLoading = false
      }
    },
    sortQuestions(e) {
      this.isSortDirDesc = e.sortDesc
      this.sortBy = e.sortBy
      this.fetchQuestions()
    },
    changePage(e) {
      this.currentPage = e
      this.fetchQuestions()
    },
    hideModal(questionUuid) {
      this.$refs[`modal-delete-question-${questionUuid}`].hide()
    },
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
@import '@core/scss/vue/libs/vue-select.scss';
</style>
