<template>
  <b-card
    class="mb-1"
  >
    <template #header>
      <template v-if="timeline.type === 'QUESTION' && timeline.question">
        <div>
          <span class="mr-1">
            {{ moment(timeline.date_time).format("LLL") }}
          </span>
          <span class="mr-1">
            <b-badge :variant="resolveStatusVariant(timeline.question.status)">
              {{ timeline.question.status }}
            </b-badge>
          </span>
          <span>
            <b-badge variant="light-info">
              {{ timeline.question.points_to_win }} points
            </b-badge>
          </span>
        </div>
        <div>
          <b-button
            variant="warning"
            class="btn-icon"
            :to="{ name: 'questions-view', params: { idCompetition: competition.uuid, id: timeline.question.uuid } }"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </div>
      </template>
      <template v-if="timeline.type === 'FIXTURE' && timeline.fixture">
        <div>
          <span class="mr-1">
            {{ moment(timeline.date_time).format("LLL") }}
          </span>
          <span class="mr-1">
            <b-badge :variant="resolveStatusVariant(timeline.fixture.status)">
              {{ timeline.fixture.status }}
            </b-badge>
          </span>
          <span class="mr-1">
            <b-badge variant="light-info">
              {{ points(timeline.fixture) }} points
            </b-badge>
          </span>
          <span>
            <b-badge variant="light-success">
              {{ timeline.fixture.stage }}
            </b-badge>
          </span>
        </div>
        <div>
          <b-button
            variant="warning"
            class="btn-icon"
            :to="{ name: 'fixtures-view', params: { idCompetition: competition.uuid, id: timeline.fixture.uuid } }"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </div>
      </template>
    </template>
    <template v-if="timeline.type === 'QUESTION' && timeline.question">
      <p class="text-center">
        {{ timeline.question.title['fr'] }}
      </p>
      <b-row>
        <b-col
          v-for="answer in timeline.question.answers"
          :key="answer.uuid"
        >
          <div>
            <b-img
              center
              height="auto"
              width="50"
              :src="answer.image"
            />
          </div>
          <div class="text-center mt-1">
            <small>{{ answer.title['fr'] }}</small>
            <template v-if="timeline.question.good_answer">
              <feather-icon
                v-show="timeline.question.good_answer.uuid === answer.uuid"
                size="14"
                class="text-success ml-1"
                icon="CheckCircleIcon"
              />
            </template>
          </div>
        </b-col>
      </b-row>
    </template>
    <template v-if="timeline.type === 'FIXTURE' && timeline.fixture">
      <b-row class="row align-items-center">
        <b-col v-if="timeline.fixture.local_team">
          <div>
            <b-img
              center
              height="auto"
              width="50"
              :src="timeline.fixture.local_team.logo_path"
            />
          </div>
          <div class="text-center mt-1">
            <small>{{ timeline.fixture.local_team.name['fr'] }}</small>
            <template v-if="timeline.fixture.winner_team">
              <feather-icon
                v-show="timeline.fixture.winner_team.team_id === timeline.fixture.local_team.team_id"
                size="14"
                class="text-success ml-1"
                icon="CheckCircleIcon"
              />
            </template>
          </div>
        </b-col>
        <b-col v-if="timeline.fixture.scores">
          <div class="text-center">
            <h4>{{ timeline.fixture.scores.ft_score }}</h4>
            <small>{{ timeline.fixture.scores.ps_score }}</small>
          </div>
        </b-col>
        <b-col v-if="timeline.fixture.visitor_team">
          <div>
            <b-img
              center
              height="auto"
              width="50"
              :src="timeline.fixture.visitor_team.logo_path"
            />
          </div>
          <div class="text-center mt-1">
            <small>{{ timeline.fixture.visitor_team.name['fr'] }}</small>
            <template v-if="timeline.fixture.winner_team">
              <feather-icon
                v-show="timeline.fixture.winner_team.team_id === timeline.fixture.visitor_team.team_id"
                size="14"
                class="text-success ml-1"
                icon="CheckCircleIcon"
              />
            </template>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BImg,
  BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'

export default {
  name: 'CompetitionsViewTimelineCard',
  components: {
    BBadge,
    BRow,
    BCol,
    BCard,
    BButton,
    BImg,
  },
  setup() {
    const resolveStatusVariant = status => {
      if (status === 'PENDING') return 'light-info'
      if (status === 'RUNNING') return 'light-success'
      if (status === 'FINISHED') return 'light-warning'
      return 'light-success'
    }
    const resolveBooleanVariant = bool => (bool ? 'light-success' : 'light-danger')
    return {
      avatarText,
      resolveStatusVariant,
      resolveBooleanVariant,
    }
  },
  props: {
    timeline: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      competition: 'competitions/competition',
    }),
  },
  methods: {
    points(fixture) {
      const { stage } = fixture
      if (stage === '8th Finals' || stage === 'Quarter-finals' || stage === 'Semi-finals' || stage === '3rd Place Final' || stage === 'Final') {
        return '0/6'
      }
      return '0/3'
    },
  },
}
</script>

<style scoped>

</style>
