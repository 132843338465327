<template>
  <b-col lg="8">
    <b-tabs
      v-model="tabIndex"
      class="w-100"
      pills
      align="center"
    >
      <b-tab
        title="A venir"
      >
        <template v-if="loading">
          <div class="text-center mt-5">
            <b-spinner label="Loading..." />
          </div>
        </template>
        <template v-else>
          <CompetitionsViewTimelineCard
            v-for="timeline in timelines"
            :key="timeline.uuid"
            :timeline="timeline"
          />
          <infinite-loading
            ref="InfiniteLoading"
            spinner="spiral"
            :identifier="infiniteLoadingId"
            @infinite="loadMore"
          />
        </template>
      </b-tab>
      <b-tab
        title="En cours"
      >
        <template v-if="loading">
          <div class="text-center mt-5">
            <b-spinner label="Loading..." />
          </div>
        </template>
        <template v-else>
          <CompetitionsViewTimelineCard
            v-for="timeline in timelines"
            :key="timeline.uuid"
            :timeline="timeline"
          />
          <infinite-loading
            ref="InfiniteLoading"
            spinner="spiral"
            :identifier="infiniteLoadingId"
            @infinite="loadMore"
          />
        </template>
      </b-tab>
      <b-tab title="Terminés">
        <template v-if="loading">
          <div class="text-center mt-5">
            <b-spinner label="Loading..." />
          </div>
        </template>
        <template v-else>
          <CompetitionsViewTimelineCard
            v-for="timeline in timelines"
            :key="timeline.uuid"
            :timeline="timeline"
          />
          <infinite-loading
            ref="InfiniteLoading"
            spinner="spiral"
            :identifier="infiniteLoadingId"
            @infinite="loadMore"
          />
        </template>
      </b-tab>
    </b-tabs>
  </b-col>
</template>

<script>
import {
  BCol,
  BSpinner, BTab,
  BTabs,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CompetitionsViewTimelineCard from '@/views/competitions/competitions-view/CompetitionsViewTimelineCard'
import axiosIns from '@/libs/axios'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'CompetitionsViewTimeline',
  components: {
    BCol,
    BSpinner,
    BTabs,
    BTab,
    CompetitionsViewTimelineCard,
    InfiniteLoading,
  },
  data() {
    return {
      loading: true,
      timelines: [],
      timelineLinksInfos: null,
      tabIndex: 0,
      infiniteLoadingId: +new Date(),
      status: {
        0: 'PENDING',
        1: 'RUNNING',
        2: 'FINISHED',
      },
    }
  },
  computed: {
    nextLink() {
      return this.timelineLinksInfos ? this.timelineLinksInfos.next : null
    },
  },
  watch: {
    tabIndex: {
      handler() {
        this.fetchTimeline()
      },
    },
  },
  mounted() {
    this.fetchTimeline()
  },
  methods: {
    async fetchTimeline() {
      try {
        this.loading = true
        const { data } = await axiosIns(`/competitions/${this.$route.params.id}/timeline`, {
          params: {
            status: this.status[this.tabIndex],
            sortDirection: this.status[this.tabIndex] === 'PENDING' ? 'asc' : 'desc',
          },
        })
        this.timelines = data.data
        this.timelineLinksInfos = data.links
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async loadMore($state) {
      if (this.nextLink) {
        try {
          const { data } = await axiosIns(this.nextLink)
          this.timelines = this.timelines.concat(data.data)
          this.timelineLinksInfos = data.links
          $state.loaded()
        } catch (err) {
          this.$toast(
            {
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erreur',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: `Une erreur s'est produite. erreur: ${err}`,
              },
            },
            { timeout: 5000 },
          )
        }
      } else {
        $state.complete()
      }
    },
  },
}
</script>

<style scoped>

</style>
